import { Button, SuaveChip, SuaveTextField2, SuaveValuedSlider } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import { parseCurrentCourseLevel } from 'components/ListingEngine/utils';
import React, { useState } from 'react';
import { CAP_COURSE_LEVELS_MAPPING, LIST_CAP_COURSE_LEVELS } from '../Constants';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import { PAFSubtitle } from './Components/PAFSubtitle';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';

interface CourseLevelData {
  currentCourseLevel?: string;
  grading?: CourseGrading;
  marksInPercent?: number;
  backlogCount?: number;
}

const gradingValues = [100, 10, 5] as const;
type CourseGrading = typeof gradingValues[number];

export const CapCurrentCourseLevelSelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;

  const [currentData , setCurrentData] = useState<CourseLevelData>(props.pafData[pageKey]
    ?? { backlogCount: 0 , grading: 100 });


  if (!props.pafData[pageKey]) {
    // Current Course Level
    const currentCourseLevel = parseCurrentCourseLevel(props.urlParams);
    if (currentCourseLevel) {
      props.setData({
        currentCourseLevel: currentCourseLevel.currentCourseLevel,
        marksInPercent: currentCourseLevel.marksInPercent,
        grading: 100,
      });
    }
  }

  const hasSelection = props.pafData[pageKey] && currentData?.currentCourseLevel;

  const  handleCourseLevelData = (data?: Partial<CourseLevelData>)=> {
    if (data) {
      setCurrentData({ ...currentData, ...data });
      props.setData({ ...currentData, ...data });
    } else {
      setCurrentData({ ...currentData });
    }
  } ;

  const goToNext = () => {
    pushToDataLayer({
      event: 'level_edulevel',
      data: {
        ['current_course_level_name']: CAP_COURSE_LEVELS_MAPPING[props.pafData[pageKey].currentCourseLevel],
        ['current_course_level_grading']: props.pafData[pageKey].grading,
        ['current_course_level_marks']: props.pafData[pageKey].marksInPercent,
        ['current_course_level_backlogs']: props.pafData[pageKey].backlogCount,
      },
    });
    Analytics.track('level_edulevel', {
      ['current_course_level_name']: CAP_COURSE_LEVELS_MAPPING[props.pafData[pageKey].currentCourseLevel],
      ['current_course_level_grading']: props.pafData[pageKey].grading,
      ['current_course_level_marks']: props.pafData[pageKey].marksInPercent,
      ['current_course_level_backlogs']: props.pafData[pageKey].backlogCount,
    });
    Analytics.people.set({
      ['current_course_level_name']: CAP_COURSE_LEVELS_MAPPING[props.pafData[pageKey].currentCourseLevel],
      ['current_course_level_grading']: props.pafData[pageKey].grading,
      ['current_course_level_marks']: props.pafData[pageKey].marksInPercent,
      ['current_course_level_backlogs']: props.pafData[pageKey].backlogCount,
    });
    props.goToNext();
  };

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="what is your current education level?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerMedium}>
        {LIST_CAP_COURSE_LEVELS.map((item) => {
          const isSelected = currentData?.currentCourseLevel === item.slug;
          return (
            <SuaveChip
              key={item.slug}
              label={item.label}
              size='large'
              isSelected={isSelected }
              isInActive={!isSelected && !!(currentData?.currentCourseLevel && currentData?.currentCourseLevel?.length > 0)}
              onClick={()=>handleCourseLevelData({ currentCourseLevel: item.slug })}
            />
          );
        })}
      </div>
      {hasSelection && (
        <>
          <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' , display: 'flex' , alignItems: 'center' }}>
            <PAFSubtitle label="select grading" />
            <div style={{ display: 'flex' , alignItems: 'center' , gap : '1rem' }}>   <SuaveChip
              label={'100'}
              size={'extraSmall'}
              isSelected={currentData?.grading === 100 }
              isInActive={currentData?.grading !== 100 && !!(currentData?.grading)}
              onClick={ ()=> handleCourseLevelData({ grading : 100})}
            />
            <SuaveChip
              label={'10'}
              size={'extraSmall'}
              isSelected={currentData?.grading === 10 }
              isInActive={currentData?.grading !== 10 && !!(currentData?.grading)}
              onClick={ ()=>handleCourseLevelData({ grading : 10})}
            />
            <SuaveChip
              label={'5'}
              size={'extraSmall'}
              isSelected={currentData?.grading === 5 }
              isInActive={ currentData?.grading && currentData?.grading !== 5}
              onClick={ ()=>handleCourseLevelData({ grading : 5})}
            />  </div>
          </div>
          {!!(currentData?.grading )&& (
            <SuaveTextField2
              label={'enter your marks'}
              value={(currentData?.marksInPercent ?? 0).toString()}
              hint={`out of ${currentData.grading}`}
              error={(currentData?.marksInPercent ?? 0) >= currentData?.grading}
              helperText={(currentData?.marksInPercent ?? 0) >= currentData?.grading ? 'marks should not be greater than grading' : ''}
              onChange={(event) => handleCourseLevelData({ marksInPercent : Number(event.target.value) as CourseGrading})}
            />
          )}
          {currentData?.currentCourseLevel !== 'twelfth' && (
            <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>{<PAFSubtitle label="backlogs" />}</div>
          )}
          {currentData?.currentCourseLevel !== 'twelfth' && (
            <SuaveValuedSlider
              key={`paf-filter-input-current-course-level-backlog-exam-${currentData.currentCourseLevel}-grading-${currentData.grading}`}
              min={0}
              max={6}
              maxLabel={'5+'}
              value={currentData.backlogCount}
              onChange={(val) => handleCourseLevelData({ backlogCount: Number(val) })}
              label="backlog count"
            />
          )}
          <div style={{ margin: '2.125rem 0 0 0' }} />
          <div className={classes.buttonContainer}>
            <Button
              id="next-button-current-course-level-select"
              isRounded={true}
              color="indigo"
              hasArrowIcon
              disabled={
                (currentData?.marksInPercent === 0 || !currentData?.marksInPercent) ||
                (currentData?.marksInPercent ?? 0) > (currentData?.grading ?? 0)}
              onClick={goToNext}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
