import { http, BackendErrorObject, AxiosError, BackendSuccessObject } from '@admitkard/uiutils/http';
import { env } from '@admitkard/uiutils/env';
import { LeadSourceObject, BankDetailsPayload, MyReferral, StudentProfileData } from './referralEngineTypes';

const REFERRAL_ENGINE_API = {
  CREATE_REFERRAL: `${env.AUTH_API}/auth/student-account/v2`,
  CREATE_ANONYMOUS_REFERRAL: `${env.AUTH_API}/student-account/v2`,
  GET_REFERRALS: `${env.IAM_API}/auth/user/referrals`,
  GET_STUDENT_PROFILE: `${env.SMS_API}auth/student-profile/my-profile`,
  GET_LEAD_SOURCE: `${env.LEGACY_SERVICE_API}/leadSources`,
  GET_PAYMENT_DETAILS: `${env.LEGACY_SERVICE_API}/auth/user/bank/details`,
  SAVE_PAYMENT_DETAILS: `${env.LEGACY_SERVICE_API}/auth/user/bank/details`,
};

export interface PaymentDetails {
  _id: string;
  user: string;
  accountType: string;
  createdAt: string;
  paytmNumber?: string;
  paypalNumber?: string;
  upiId?: string;
  bankName?: string;
  accountNumber?: string;
  ifsc?: string;
  bankAccountName?: string;
  updatedAt: string;
  beneficiaryName?: string;
  bankAccountType?: string;
}

export const userDetailsPayload = {
  filters: {
    priorityLevel: ['p3', 'p1', 'p2', 'p0', 'p10', 'p4'],
  },
  options: {
    suggestions: false,
    userValues: true,
    populate: true,
  },
};

export const getStudentProfile = (fieldsToGet: string[]) => {
  return http
    .get<StudentProfileData>(REFERRAL_ENGINE_API.GET_STUDENT_PROFILE, { params: { select: fieldsToGet } })
    .then((response) => response.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response.data;
    });
};

export const getLeadSources = () => {
  return http
    .get<BackendSuccessObject<LeadSourceObject[]>>(REFERRAL_ENGINE_API.GET_LEAD_SOURCE)
    .then((response) => response.data.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response.data;
    });
};

export const createReferral = (payload: any) => {
  return http
    .post(REFERRAL_ENGINE_API.CREATE_REFERRAL, payload)
    .then((response) => response.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response.data;
    });
};

export const createAnonymousReferral = (payload: any) => {
  return http
    .post(REFERRAL_ENGINE_API.CREATE_ANONYMOUS_REFERRAL, payload)
    .then((response) => response.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response;
    });
};

export const getPaymentDetails = () => {
  return http
    .get<BackendSuccessObject<PaymentDetails>>(REFERRAL_ENGINE_API.GET_PAYMENT_DETAILS)
    .then((response) => response.data.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response.data;
    });
};

export const saveBankDetails = (bankDetails: BankDetailsPayload) => {
  return http
    .post<BackendSuccessObject<any>>(REFERRAL_ENGINE_API.SAVE_PAYMENT_DETAILS, bankDetails)
    .then((response) => response.data.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response.data;
    });
};

export const getMyReferrals = () => {
  return http
    .get<BackendSuccessObject<MyReferral[]>>(REFERRAL_ENGINE_API.GET_REFERRALS)
    .then((response) => response.data.data)
    .catch((err: AxiosError<BackendErrorObject>) => {
      throw err.response && err.response.data;
    });
};
