import { PAF_PAGES_KEY } from 'components/ListingEngine';
import type { BaseLandingPageConfig, HomePageConfig, QuestionConfig } from 'types';

export const DEFAULT_HOMEPAGE_CONFIG: HomePageConfig = {
  id: 'DEFAULT_HOMEPAGE_CONFIG',
  sectionConfig: {
    sections: [
      'section-header',
      'section-testimonial',
      'section-features',
      'section-mentor-chat',
      'section-course-finder',
      'section-mentor-x',
      'section-blog',
      'section-admitkard-in-media',
      'section-faq',
      'section-rating'
    ],
  },
  primaryMessage: 'study abroad\nwith expert\nguidance from\n *international students*',
  shouldAttachQueryParams: false,
  primaryCTA: {
    CTALabel: 'Find my dream university',
    CTAId: 'primary-cta',
    redirectionURL: '/paf',
  },
};

export const DEFAULT_SCHOLARSHIP_CONFIG: BaseLandingPageConfig = {
  id: 'default-scholarship',
  landingBannerConfig: {
    text: '',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to expert',
    backgroundPosition: 'bottom',
    hideCta: false,
    backgroundIllustration: 'https://assets.admitkard.com/images/banner-listing-scholarships.webp',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to Expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaText: 'Know more',
    secondaryCtaAction: 'callFunction',
    secondaryCtaActionId: 'detailsPage',
    secondaryCtaHide: false,
  },
};

export const DEFAULT_ACCOMMODATIONS_CONFIG: BaseLandingPageConfig = {
  id: 'default-accommodations',
  landingBannerConfig: {
    text: '',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to expert',
    backgroundPosition: 'bottom',
    hideCta: false,
    backgroundIllustration: 'https://assets.admitkard.com/images/banner-listing-scholarships.webp',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to Expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaHide: false,
  },
};

export const DEFAULT_COURSES_CONFIG: BaseLandingPageConfig = {
  id: 'default-course',
  landingBannerConfig: {
    text: 'need more help in finding your dream course?',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to Expert',
    bannerIllustration: 'https://assets.admitkard.com/images/paf/plane_2.svg',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to Expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaText: 'Shortlist',
    secondaryCtaAction: 'callFunction',
    secondaryCtaActionId:  'shortlist',
  }
};

export const DEFAULT_COURSE_PAF_CONFIG: QuestionConfig = {
  id: 'DEFAULT_PAF_FLOW',
  pageConfig: {
    pages: [
      'COUNTRY_SELECT',
      'COURSE_LEVEL_SELECT',
      'AREA_OF_STUDY_SELECT',
      'CURRENT_COURSE_LEVEL_SELECT',
      PAF_PAGES_KEY.INTAKE_SELECT,
      'ENGLISH_TEST_SELECT',
      'APTITUDE_TEST_SELECT',
      'WORK_EXPERIENCE_SELECT',
    ],
  },
  redirectionUrl: '/courses',
  loginBefore: false,
  shouldSubmitPafData: true,
  hasLogin: true,
  bannerConfig: {
    bannerId: 'DEFAULT_PAF_BANNER',
    text: 'Let’s help you find your dream',
    backgroundIllustration: 'https://assets.admitkard.com/images/world_map.png',
    bannerIllustration: 'https://assets.admitkard.com/images/plane.svg',
  },
  landingBannerConfig: {
    text: 'need more help in finding your dream course?',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to expert',
    bannerIllustration: 'https://assets.admitkard.com/images/paf/plane_2.svg',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaText: 'Shortlist',
    secondaryCtaAction: 'callFunction',
    secondaryCtaActionId:  'shortlist',
  },
  signInConfig : {
    bannerConfig : {
      text: 'need more help in finding your dream course?',
      backgroundIllustration: 'https://assets.admitkard.com/images/generic_banner.svg',
      backgroundIllustrationMobile: 'https://assets.admitkard.com/images/generic_banner_mobile.svg',
    }
  }
};

export const DEFAULT_ACCOMMODATION_PAF_CONFIG: QuestionConfig = {
  id: 'DEFAULT_ACCOMMODATION_PAF_FLOW',
  pageConfig: {
    pages: [
      PAF_PAGES_KEY.SEARCHABLE_COUNTRY_SELECT,
      PAF_PAGES_KEY.SEARCHABLE_CITY_SELECT,
      PAF_PAGES_KEY.INTAKE_SELECT,
    ],
  },
  redirectionUrl: '/accommodations',
  loginBefore: false,
  shouldSubmitPafData: true,
  hasLogin: true,
  bannerConfig: {
    bannerId: 'DEFAULT_ACCOMMODATION_PAF_BANNER',
    text: '',
    backgroundIllustration: 'https://assets.admitkard.com/images/banner-paf-full-desktop-accommodation.png',
    backgroundIllustrationMobile: 'https://assets.admitkard.com/images/banner-paf-full-mobile-accommodation.png',
    backgroundPosition: 'bottom',
  },
  landingBannerConfig: {
    text: 'talk to expert within 2 min for scholarship',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to Expert',
    bannerIllustration: 'https://assets.admitkard.com/images/banner-listing-scholarships.webp',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to Expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaHide: true,
  },
  signInConfig : {
    bannerConfig : {
      text: '',
      backgroundIllustration: 'https://assets.admitkard.com/images/banner-paf-full-desktop-accommodation.png',
      backgroundIllustrationMobile: 'https://assets.admitkard.com/images/banner-paf-full-mobile-accommodation.png',
    },
  }
};

export const DEFAULT_SCHOLARSHIP_PAF_CONFIG: QuestionConfig = {
  id: 'DEFAULT_SCHOLARSHIP_PAF_FLOW',
  pageConfig: {
    pages: [
      'COUNTRY_SELECT',
      'COURSE_LEVEL_SELECT',
      'AREA_OF_STUDY_SELECT',
      'INTAKE_SELECT',
    ],
  },
  redirectionUrl: '/scholarships',
  loginBefore: false,
  shouldSubmitPafData: true,
  hasLogin: true,
  bannerConfig: {
    bannerId: 'DEFAULT_SCHOLARSHIP_PAF_BANNER',
    text: '',
    backgroundIllustration: 'https://assets.admitkard.com/images/banner-paf-full-desktop-scholarship.webp',
    backgroundIllustrationMobile: 'https://assets.admitkard.com/images/banner-paf-full-mobile-scholarship.webp',
    backgroundPosition: 'bottom',
  },
  landingBannerConfig: {
    text: '',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to Expert',
    bannerIllustration: 'https://assets.admitkard.com/images/banner-listing-scholarships.webp',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to Expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaHide: true,
  },
  signInConfig : {
    bannerConfig : {
      text: '',
      backgroundIllustration: 'https://assets.admitkard.com/images/banner-paf-full-desktop-scholarship.webp',
      backgroundIllustrationMobile: 'https://assets.admitkard.com/images/banner-paf-full-mobile-scholarship.webp',
    }
  }
};

export const DEFAULT_CAP_COURSE_PAF_CONFIG: QuestionConfig = {
  id: 'DEFAULT_PAF_FLOW',
  pageConfig: {
    pages: [
      'CAP_AREA_OF_STUDY_SELECT',
      'CAP_CURRENT_COURSE_LEVEL_SELECT',
      'CAP_WORK_EXPERIENCE_SELECT',
      'CAP_RECEIVED_INFO',
    ],
  },
  redirectionUrl: '/cap',
  loginBefore: true,
  shouldSubmitPafData: true,
  hasLogin: true,
  bannerConfig: {
    bannerId: 'DEFAULT_PAF_BANNER',
    text: 'Let’s help you find your dream',
    backgroundIllustration: 'https://assets.admitkard.com/images/world_map.png',
    bannerIllustration: 'https://assets.admitkard.com/images/plane.svg',
  },
  landingBannerConfig: {
    text: 'need more help in finding your dream course?',
    action: 'callFunction',
    actionId: 'openExpertConnectPopup',
    ctaText: 'Talk to expert',
    bannerIllustration: 'https://assets.admitkard.com/images/paf/plane_2.svg',
  },
  landingCardConfig: {
    primaryCtaText: 'Talk to expert',
    primaryCtaAction: 'callFunction',
    primaryCtaActionId:  'openExpertConnectPopup',
    secondaryCtaText: 'Shortlist',
    secondaryCtaAction: 'callFunction',
    secondaryCtaActionId:  'shortlist',
  },
  signInConfig : {
    bannerConfig : {
      text: 'need more help in finding your dream course?',
      backgroundIllustration: 'https://assets.admitkard.com/images/generic_banner.svg',
      backgroundIllustrationMobile: 'https://assets.admitkard.com/images/generic_banner_mobile.svg',
    }
  }
};
