import { env } from '@admitkard/uiutils/env';

export const PRE_LOGIN_URLS = {
  //! Listing Courses
  PAFCourses: '/paf',
  ListingCourses: '/courses',
  //! Listing Scholarship
  PAFScholarship: '/scholarships/paf',
  ListingScholarship: '/scholarships',
  //! Listing Accommodations
  PAFAccommodations: '/accommodations/paf',
  ListingAccommodations: '/accommodations',
  //! Listing Cap
  PAFCap: '/cap/paf',
  ListingCap: '/cap',
  //! Static Listing Pages
  ListingCourseStatic : '/course',
  ListingAccommodationStatic:'/accommodation',
  ListingScholarshipStatic:'/scholarship',
  //! Landing page for ads
  Generic: '/generic',
  //! Login
  Login: '/login',
} as const;

export const ACMS_BASE_URL = `${env.ACMS_API}/accommodation`;
