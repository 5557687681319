import React from 'react';
import { Link } from '@admitkard/ccl/Link';
import css from 'styled-jsx/css';
import { theme } from '@admitkard/ccl/style';
import { Img } from '@admitkard/ccl/Img';
import { useRouter } from 'next/router';
import { PRE_LOGIN_URLS } from 'constants/links';

const styles = css`
.whatsappLink__container {
  position: fixed;
    right: ${theme.spacingOld(4)};
    bottom: 4rem;
  }
  .whatsappLink__container img {
    width: 3.375rem;
  }
  @media ${theme.mediaQuery.tabletUp} {
    .whatsappLink__container {
      bottom: ${theme.spacingOld(5)};
    }
    .whatsappLink__container img {
      width: 4.375rem;
    }
  }
`;

const WhatsappLink: React.FunctionComponent = () => {
  const whatsappApiUrl= 'https://api.whatsapp.com/send?phone=918448382362&text=Hey%20I%20want%20to%20study%20abroad&source=&data=';
  const whatsappImageUrl = 'https://d3toi7c4ip49i3.cloudfront.net/img/logo/whatsapp2-01-01.png';
  const router = useRouter();

  return _isHidden(router.route)
    ? <></>
    : <div className="whatsappLink__container">
      <Link href={whatsappApiUrl} target="_blank">
        <Img src={whatsappImageUrl} alt="whatsapp" style={{width: '2.79rem', height: '3rem'}} />
      </Link>
      <style jsx>{styles}</style>
    </div>;
};

/**
 * determines component to be hidden for specific routes
 * @param pathName current pathName got from next router
 * @returns true if current routes is in exclusion list
 */
function _isHidden(pathName: string) : boolean {
  // normal routes
  const normalRoutes = [
    '/paf',
    '/bigleague',
    '/cap',
    PRE_LOGIN_URLS.ListingCourses,
    PRE_LOGIN_URLS.ListingScholarship,
    PRE_LOGIN_URLS.ListingAccommodations,
  ];

  const dynamicRoutes = [
    PRE_LOGIN_URLS.ListingScholarship,
    PRE_LOGIN_URLS.ListingAccommodations,
    PRE_LOGIN_URLS.ListingCourseStatic,
    PRE_LOGIN_URLS.ListingAccommodationStatic,
    PRE_LOGIN_URLS.ListingScholarshipStatic,
    PRE_LOGIN_URLS.ListingCourses,
    '/bigleague',
    '/tcl',
    '/cap',
  ];

  if(normalRoutes.some((hiddenRoute) => hiddenRoute === pathName)) {
    return true;
  } else if(dynamicRoutes.some((hiddenRoute) => pathName.startsWith(`${hiddenRoute}`))) {
    return true;
  } else {
    return false;
  }
}

export default WhatsappLink;
