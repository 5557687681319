import { makeStyles } from '@admitkard/ccl';
import { ParsedUrlQuery } from 'querystring';
import React from 'react';
import { AptitudeTestTestSelect } from './QuestionPages/AptitudeTestSelect';
import { AreaOfStudySelect } from './QuestionPages/AreaOfStudySelect';
import { CountrySelect } from './QuestionPages/CountrySelect';
import { CourseLevelSelect } from './QuestionPages/CourseLevelSelect';
import { CurrentCourseLevelSelect } from './QuestionPages/CurrentCourseLevelSelect';
import { EnglishTestSelect } from './QuestionPages/EnglishTestSelect';
import { WorkExperienceSelect } from './QuestionPages/WorkExperienceSelect';
import { IntakeSelect } from './QuestionPages/IntakeSelect';
import { SearchableCountrySelect } from './QuestionPages/SearchableCountrySelect';
import { SearchableCitySelect } from './QuestionPages/SearchableCitySelect';
import { SearchableCurrentCitySelect } from './QuestionPages/SearchableCurrentCitySelect';
import { CapReceivedInfo } from './QuestionPages/CapReceivedInfo';
import { CapAreaOfStudySelect } from './QuestionPages/CapAreaOfStudySelect';
import { CapCurrentCourseLevelSelect } from './QuestionPages/CapCurrentCourseLevelSelect';
import { CapWorkExperienceSelect } from './QuestionPages/CapWorkExperienceSelect';

const useStyles = makeStyles(
  () => ({
    question: {
      flex: 1.8,
      maxWidth: '41rem',
      '@media screen and (max-width: 991px)': {
        flex: 'auto',
        maxWidth: '100%',
      },
      '@media screen and (max-width: 459px)': {
        margin: '0rem 1rem',
      },
    },
  }),
  { name: 'QuestionRenderer' }
);

interface QuestionRendererProps {
  pageConfig: any;
  currentPage: number;
  setData: (pageKey: string, data?: any) => void;
  goToNext: () => void;
  goToPrevious: () => void;
  pafData: Record<string, any>;
  urlParams: ParsedUrlQuery;
  externalData?: Record<string, any>;
}

export const QuestionRenderer: React.FunctionComponent<QuestionRendererProps> = (props) => {
  const classes = useStyles();

  const RENDERER_CONSTANT: Record<string, JSX.Element> = {
    COUNTRY_SELECT: (
      <CountrySelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="COUNTRY_SELECT"
        setData={(data) => props.setData('COUNTRY_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    COURSE_LEVEL_SELECT: (
      <CourseLevelSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="COURSE_LEVEL_SELECT"
        setData={(data) => props.setData('COURSE_LEVEL_SELECT', data)}
        urlParams={props.urlParams}
        resetCompetitiveExamMarks={() => props.setData('APTITUDE_TEST_SELECT', {})}
      />
    ),
    CURRENT_COURSE_LEVEL_SELECT: (
      <CurrentCourseLevelSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="CURRENT_COURSE_LEVEL_SELECT"
        setData={(data) => props.setData('CURRENT_COURSE_LEVEL_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    AREA_OF_STUDY_SELECT: (
      <AreaOfStudySelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="AREA_OF_STUDY_SELECT"
        setData={(data) => props.setData('AREA_OF_STUDY_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    ENGLISH_TEST_SELECT: (
      <EnglishTestSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="ENGLISH_TEST_SELECT"
        setData={(data) => props.setData('ENGLISH_TEST_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    APTITUDE_TEST_SELECT: (
      <AptitudeTestTestSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="APTITUDE_TEST_SELECT"
        setData={(data) => props.setData('APTITUDE_TEST_SELECT', data)}
        urlParams={props.urlParams}
        isUG={props.pafData['COURSE_LEVEL_SELECT'] === 'ug'}
      />
    ),
    WORK_EXPERIENCE_SELECT: (
      <WorkExperienceSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="WORK_EXPERIENCE_SELECT"
        setData={(data) => props.setData('WORK_EXPERIENCE_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    INTAKE_SELECT: (
      <IntakeSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="INTAKE_SELECT"
        setData={(data) => props.setData('INTAKE_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    SEARCHABLE_COUNTRY_SELECT: (
      <SearchableCountrySelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="SEARCHABLE_COUNTRY_SELECT"
        setData={(data) => props.setData('SEARCHABLE_COUNTRY_SELECT', data)}
        urlParams={props.urlParams}
        countries={props.externalData?.countries}
      />
    ),
    SEARCHABLE_CITY_SELECT: (
      <SearchableCitySelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="SEARCHABLE_CITY_SELECT"
        setData={(data) => props.setData('SEARCHABLE_CITY_SELECT', data)}
        urlParams={props.urlParams}
        countryInfo={props.pafData['SEARCHABLE_COUNTRY_SELECT']}
      />
    ),
    SEARCHABLE_CURRENT_CITY_SELECT: (
      <SearchableCurrentCitySelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="SEARCHABLE_CURRENT_CITY_SELECT"
        setData={(data) => props.setData('SEARCHABLE_CURRENT_CITY_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    CAP_CURRENT_COURSE_LEVEL_SELECT: (
      <CapCurrentCourseLevelSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="CAP_CURRENT_COURSE_LEVEL_SELECT"
        setData={(data) => props.setData('CAP_CURRENT_COURSE_LEVEL_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    CAP_AREA_OF_STUDY_SELECT: (
      <CapAreaOfStudySelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="CAP_AREA_OF_STUDY_SELECT"
        setData={(data) => props.setData('CAP_AREA_OF_STUDY_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    CAP_WORK_EXPERIENCE_SELECT: (
      <CapWorkExperienceSelect
        currentPage={props.currentPage}
        goToNext={props.goToNext}
        goToPrevious={props.goToPrevious}
        pafData={props.pafData}
        pageKey="CAP_WORK_EXPERIENCE_SELECT"
        setData={(data) => props.setData('CAP_WORK_EXPERIENCE_SELECT', data)}
        urlParams={props.urlParams}
      />
    ),
    CAP_RECEIVED_INFO: (
      <CapReceivedInfo />
    )
  };

  const getPage = () => {
    const currentPageString: string = props.pageConfig.pages[props.currentPage];
    return RENDERER_CONSTANT[currentPageString];
  };

  return <div className={classes.question}>{getPage()}</div>;
};
