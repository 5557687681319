import { Button, SuaveChip, SuaveValuedSlider } from '@admitkard/ccl';
import pushToDataLayer from '@admitkard/uiutils/gTagDataLayer';
import React from 'react';
import { PafPageProps } from '../Types';
import { QuestionHeader } from './Components';
import { PAFSubtitle } from './Components/PAFSubtitle';
import styles from './questionStyles.css';
import { Analytics } from '@admitkard/uiutils/analytics/events';
import { LIST_PROGRAMMING_EXPERTISE } from '../Constants';

interface WorkExperienceData {
  hasWorkExperience?: boolean;
  numberOfYears?: number;
  programmingExpertise?: string;
}

export const CapWorkExperienceSelect: React.FunctionComponent<PafPageProps> = (props) => {
  const classes = styles();
  const { pageKey } = props;
  const currentData: WorkExperienceData = props.pafData[pageKey] ?? { numberOfYears: 0 };
  const hasSelection = props.pafData[pageKey];

  const handleProgrammingExpertise = (data?: Partial<WorkExperienceData>) => {
    if (data) {
      props.setData({ ...currentData, ...data });
    } else {
      props.setData(undefined);
    }
  };


  const goToNext = () => {
    pushToDataLayer({
      event: 'level_workexp',
      data: {
        ['work_experience']: props.pafData[pageKey].hasWorkExperience,
        ['work_experience_years']: props.pafData[pageKey].numberOfYears,
        ['programming_expertise']: props.pafData[pageKey].programmingExpertise,
      },
    });
    Analytics.track('level_workexp', {
      ['work_experience']: props.pafData[pageKey].hasWorkExperience,
      ['work_experience_years']: props.pafData[pageKey].numberOfYears,
      ['programming_expertise']: props.pafData[pageKey].programmingExpertise,
    });
    Analytics.people.set({
      ['work_experience']: props.pafData[pageKey].hasWorkExperience,
      ['work_experience_years']: props.pafData[pageKey].numberOfYears,
      ['programming_expertise']: props.pafData[pageKey].programmingExpertise,
    });
    props.goToNext();
  };

  return (
    <div className={classes.questionContainer}>
      <QuestionHeader
        questionText="do you have any work experience?"
        isFirstPage={props.currentPage === 0}
        onBackClick={props.goToPrevious}
      />
      <div className={classes.optionsContainerSmall}>
        <SuaveChip
          key="yes"
          label="Yes"
          icon={'yes'}
          size="small"
          isSelected={currentData.hasWorkExperience === true}
          isInActive={currentData.hasWorkExperience === false}
          onClick={() => addData(currentData.hasWorkExperience === true ? undefined : { hasWorkExperience: true })}
        />
        <SuaveChip
          key="no"
          label="No"
          icon={'none'}
          size="small"
          isSelected={currentData.hasWorkExperience === false}
          isInActive={currentData.hasWorkExperience === true}
          onClick={() => addData(currentData.hasWorkExperience === false ? undefined : { hasWorkExperience: false })}
        />
      </div>
      {currentData.hasWorkExperience && (
        <>
          <div style={{ margin: '1.25rem 0 0.75rem 0.5rem' }}>
            {<PAFSubtitle label="how many years of experience" />}
          </div>
          <SuaveValuedSlider
            min={0}
            max={6}
            maxLabel={'5+'}
            value={currentData.numberOfYears}
            onChange={(val) => addData({ hasWorkExperience: true, numberOfYears: Number(val) })}
            label="years of experience"
          />
        </>
      )}

      <div style={{ margin: '2.25rem 0 0rem 0.5rem' }}>
        {<PAFSubtitle label="Your programming expertise" />}
      </div>
      <div className={classes.optionsContainerMedium}>
        {LIST_PROGRAMMING_EXPERTISE.map((item) => {
          const isSelected = currentData?.programmingExpertise === item.slug;
          return (
            <SuaveChip
              key={item.slug}
              label={item.label}
              size={'small'}
              isSelected={isSelected }
              isInActive={!isSelected && !!(currentData?.programmingExpertise && currentData?.programmingExpertise?.length > 0)}
              onClick={()=> handleProgrammingExpertise({  programmingExpertise: item.slug })}
            />
          );
        })}
      </div>

      <div className={classes.buttonContainer}>
        <Button
          id="next-button-work-experience-select"
          isRounded={true}
          color="indigo"
          hasArrowIcon
          disabled={!(hasSelection && currentData?.programmingExpertise && currentData.programmingExpertise.length > 0)}
          onClick={goToNext}
        >
          Next
        </Button>
      </div>
    </div>
  );

  function addData(data?: Partial<WorkExperienceData>): void {
    if (data) {
      if (!data.hasWorkExperience) {
        props.setData({
          hasWorkExperience: false,
          numberOfYears: 0,
        });
      } else {
        props.setData({
          hasWorkExperience: true,
          numberOfYears: data.numberOfYears ?? 0,
        });
      }
    } else {
      props.setData(undefined);
    }
  }
};
