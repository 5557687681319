import { Button } from '@admitkard/ccl';
import React from 'react';
import styles from './questionStyles.css';
import { useRouter } from 'next/router';

export const CapReceivedInfo: React.FunctionComponent = () => {
  const classes = styles();
  const router = useRouter();

  const goToNext = () => {
    router.push('/cap');
  };

  return (
    <div className={classes.questionContainer}>
      <div className={classes.infoHeader}>
        <div className={classes.infoHeader1}>You are</div>
        <div className={classes.infoHeader2}>amazing!</div>
      </div>
      <div className={classes.infoContent}>
        We have received your details, our counselor would get back to you soon!
      </div>
      <div className={classes.buttonContainer}>
        <Button
          id="next-button-intake-select"
          isRounded={true}
          color="indigo"
          hasArrowIcon
          onClick={goToNext}
        >
          Home
        </Button>
      </div>
    </div>
  );
};
